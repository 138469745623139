<template>
    <div>
        <h3>Invoice</h3>

        <v-btn
            text
            :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app class="mt-2">
          <div class="row" v-if="loading">
            <div class="col-md-12">
              <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
          </div>
            <v-card flat v-if="!loading && pageSetting">
                <v-card-text>
                    <v-row class="pa-10">
                        <v-col cols="12">
                            <h3>Invoice Settings</h3>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                type="number"
                                min="0"
                                max="6"
                                @blur="noOfInvoiceDigit"
                                v-model="pageSetting.invoice_no_of_digit"
                                dense
                                outlined
                                label="Total Number of Invoice Digits"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                type="number"
                                min="0"
                                v-model="pageSetting.invoice_starts_from"
                                densed
                                outlined
                                label="Start Invoicing number from"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                label="Add custom attribue to invoice number"
                                v-model="pageSetting.invoice_type"
                                :items="['prefix','suffix','automated']"
                                outlined
                                densed
                            ></v-select>
                        </v-col>

                        <v-col cols="6" v-if="pageSetting.invoice_type=='prefix'">
                            <v-text-field
                                v-model="pageSetting.invoice_type_value"
                                densed
                                outlined
                                label="Prefix Text"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="pageSetting.invoice_type=='suffix'">
                            <v-text-field
                                v-model="pageSetting.invoice_type_value"
                                densed
                                outlined
                                label="Suffix Text"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn @click="updateSetting" class="btn btn-primary" outlined text>save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <div class="justify-end"></div>
                </v-card-actions>
            </v-card>
        </v-app>
    </div>
</template>
<script>

    import CurrencySetting from "./CurrencySetting";
    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";

    const WebsiteSettingService = new WebsiteSetting();

    export default {
        name: "curr",
        data() {
            return {
                pageSetting: null,
                loading: true,
            };
        },
        components: {
            CurrencySetting,
        },
        mounted() {
            this.getSettings()
        },
        methods: {
            getSettings() {
                WebsiteSettingService.paginate(this.siteUrl).then(res => {
                    this.pageSetting = res.data;
                    this.loading = false;
                });
            },
            noOfInvoiceDigit() {
                let no = this.pageSetting.invoice_no_of_digit;
                if (no > 6) {
                    this.pageSetting.invoice_no_of_digit = 6;
                }
            },
            updateWebsiteSetting(data) {
                this.loading = true;

                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteSettingService.update(
                                    this.siteUrl,
                                    this.pageSetting.id,
                                    data
                                ).then(res => {
                                    this.$snotify.success("Settings  Updated");
                                    this.getSettings();
                                });
                            }
                        }
                    }
                )

            },
            updateSetting() {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteSettingService.update(
                                    this.siteUrl,
                                    this.pageSetting.id,
                                    this.pageSetting
                                ).then(res => {
                                    this.$snotify.success("Settings  Updated");
                                    this.getSettings();
                                });
                            }
                        }
                    }
                )

            },
        },
        computed: {

            siteUrl() {
                return this.$route.params.domainname;
            },

        },
    };
</script>
